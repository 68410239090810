import {
  CalendarIcon,
  DollarSignIcon,
  FilterIcon,
  MessageSquareIcon,
  PlusCircleIcon,
  UserIcon,
} from "lucide-react";

export const benefitOne = {
  title: "Anpassbare Bewerbungen",
  desc: "Maßgeschneiderte Antworten, die dein Profil perfekt widerspiegeln.",
  // image: benefitOneImg,
  bullets: [
    {
      title: "Automatisierte Antworten",
      desc: "AIFred analysiert deine Profildaten und generiert maßgeschneiderte Antworten auf Kundenanfragen – schnell und präzise.",
      icon: <MessageSquareIcon />,
    },
    {
      title: "Profilgesteuerte Anpassungen",
      desc: "Dein AIFred-Profil wird kontinuierlich genutzt, um Antworten nach deinem Stil und deinen Fähigkeiten anzupassen.",
      icon: <UserIcon />,
    },
    {
      title: "Skill-Matching",
      desc: "AIFred berücksichtigt deine bevorzugten Tools und Methoden, um dich perfekt mit Kundenanfragen zu vernetzen.",
      icon: <FilterIcon />,
    },
  ],
};

export const benefitTwo = {
  title: "Flexibles Nutzungserlebnis",
  desc: "Gestalte deine Verfügbarkeit und nutze AIFred nach Bedarf.",
  // image: benefitTwoImg,
  bullets: [
    {
      title: "Flexible Verfügbarkeit",
      desc: "Setze deine Verfügbarkeiten (remote, vor Ort, Teilzeit, Vollzeit) und lass AIFred das passende Match für dich erstellen.",
      icon: <CalendarIcon />,
    },
    {
      title: "Kostenloser Start",
      desc: "Erhalte nach der Registrierung kostenlose Credits und teste AIFred ohne Risiko.",
      icon: <DollarSignIcon />,
    },
    {
      title: "Erweiterbare Credits",
      desc: "Bezahle nur für das, was du brauchst. Kaufe Credits flexibel nach, wenn du sie benötigst.",
      icon: <PlusCircleIcon />,
    },
  ],
};
