import { Benefits } from "~/components/landingpage/Benefits";
import { Container } from "~/components/landingpage/Container";
import { Cta } from "~/components/landingpage/Cta";
import { benefitOne, benefitTwo } from "~/components/landingpage/data";
import { Faq } from "~/components/landingpage/Faq";
import { Hero } from "~/components/landingpage/Hero";
import { SectionTitle } from "~/components/landingpage/SectionTitle";

export default function Home() {
  return (
    <Container>
      <Hero />
      <SectionTitle
        preTitle="Was kann AIFred für mich tun?"
        title="Mit AIFred schneller zum passenden Job!"
      >
        <div>
          So sparst du Zeit und steigerst deine Chancen, den passenden Auftrag
          zu gewinnen. Einfach registrieren, Profil erstellen und sofort starten
          – mit deinen ersten kostenlosen Credits.
        </div>
      </SectionTitle>

      <Benefits data={benefitOne} />
      <Benefits imgPos="right" data={benefitTwo} />

      <SectionTitle preTitle="FAQ" title="Häufige Fragen">
        Hier finden Sie Antworten auf die am häufigsten gestellten Fragen zu
        AIFred. Erfahren Sie, wie AIFred Freelancern hilft, den
        Bewerbungsprozess zu optimieren, welche Kosten und Funktionen es gibt
        und wie Ihre Daten geschützt werden.
      </SectionTitle>

      <Faq />
      <Cta />
    </Container>
  );
}
