import { Link } from "@remix-run/react";
import { $path } from "remix-routes";
import { Container } from "~/components/landingpage/Container";

export const Hero = () => {
  return (
    <>
      <Container className="flex flex-wrap">
        <div className="flex w-full items-center">
          <div className="mb-8 max-w-2xl">
            <h1 className="text-4xl font-bold leading-snug tracking-tight text-gray-800 dark:text-white lg:text-4xl lg:leading-tight xl:text-6xl xl:leading-tight">
              AIFred - Dein Assistent als Freelancer
            </h1>
            <p className="py-5 text-xl leading-normal text-gray-500 dark:text-gray-300 lg:text-xl xl:text-2xl">
              AIFred erstellt für dich gezielte Antworten auf Kundenanfragen,
              die exakt auf dein Profil zugeschnitten sind – inklusive deiner
              Skills, Verfügbarkeit und Stundensatz.
            </p>

            <div className="flex flex-col items-start space-y-3 sm:flex-row sm:items-center sm:space-x-4 sm:space-y-0">
              <Link
                to={$path("/app")}
                className="rounded-md bg-indigo-500 px-8 py-4 text-center text-lg font-medium text-white"
              >
                Teste AIFred
              </Link>
            </div>
          </div>
        </div>
        <div className="flex w-full items-center justify-center lg:w-1/2">
          <div className=""></div>
        </div>
      </Container>
    </>
  );
};
