"use client";
import { Container } from "~/components/landingpage/Container";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/components/ui/accordion";

export const Faq = () => {
  return (
    <Container className="!p-0">
      <Accordion type="single" collapsible>
        <div className="mx-auto w-full max-w-2xl rounded-2xl p-2">
          {faqdata.map((item, index) => (
            <div key={item.question} className="mb-5">
              <AccordionItem value={`${index}`}>
                <AccordionTrigger className="text-left">{item.question}</AccordionTrigger>
                <AccordionContent>{item.answer}</AccordionContent>
            </AccordionItem>
            </div>
          ))}
        </div>
      </Accordion>
    </Container>
  );
};
const faqdata = [
  {
    question: "Was ist AIFred?",
    answer:
      "AIFred ist eine Plattform, die Freelancern hilft, den Bewerbungsprozess zu vereinfachen. AIFred erstellt intelligente Antworten auf Anfragen, die automatisch relevante Informationen zum Profil des Freelancers enthalten.",
  },
  {
    question: "Wie funktioniert AIFred?",
    answer:
      "Freelancer registrieren sich bei AIFred und erstellen ein Profil mit Informationen wie Name, Standort, Stundensatz, Skills, gewünschten und ungewünschten Tools/Methoden sowie Verfügbarkeiten. Basierend auf diesen Angaben generiert AIFred maßgeschneiderte Antworten auf Kundenanfragen.",
  },
  {
    question: "Was kostet AIFred?",
    answer:
      "AIFred verwendet ein Creditsystem. Neue Nutzer erhalten nach der Registrierung kostenlose Credits und können bei Bedarf weitere Credits nachkaufen.",
  },
  {
    question: "Welche Informationen werden für mein Profil benötigt?",
    answer:
      "Zu den Profilinformationen gehören Name, Standort, Stundensatz, Skills, präferierte Tools/Methoden, und die gewünschte Verfügbarkeit (Remote/vor Ort und Vollzeit/Teilzeit).",
  },
  {
    question:
      "Kann ich auswählen, welche Projekte AIFred für mich beantwortet?",
    answer:
      "Ja, Sie können bestimmte Präferenzen für Tools und Methoden in Ihrem Profil festlegen, sodass AIFred nur auf Anfragen antwortet, die Ihren Anforderungen entsprechen.",
  },
  {
    question: "Sind meine Daten sicher bei AIFred?",
    answer:
      "Ja, die Sicherheit und Vertraulichkeit Ihrer Daten ist uns sehr wichtig. AIFred verwendet branchenübliche Sicherheitsstandards, um Ihre Daten zu schützen.",
  },
  {
    question: "Kann ich auch Antworten auf Deutsch erstellen lassen?",
    answer:
      "Ja, AIFred unterstützt mehrsprachige Antworten, darunter auch Deutsch und Englisch, basierend auf Ihren Profilinformationen.",
  },
  {
    question: "Wie werden die Antworten auf Kundenanfragen personalisiert?",
    answer:
      "Die Antworten werden auf Basis Ihrer Profilangaben erstellt, sodass sie spezifische Details wie Ihren Standort, Stundensatz und relevante Skills enthalten.",
  },
  {
    question: "Gibt es eine kostenlose Testphase?",
    answer:
      "Ja, nach der Registrierung erhalten Sie kostenlose Credits, mit denen Sie AIFred ausprobieren können, bevor Sie weitere Credits erwerben.",
  },
  {
    question: "Wie kann ich weitere Credits kaufen?",
    answer:
      "Credits können direkt über Ihr AIFred-Konto nachgekauft werden. Weitere Informationen zum Kaufprozess finden Sie im Dashboard.",
  },
];
