import { Link } from "@remix-run/react";
import { $path } from "remix-routes";
import { Container } from "~/components/landingpage/Container";

export const Cta = () => {
  return (
    <Container>
      <div className="mx-auto flex w-full max-w-4xl flex-wrap items-center justify-between gap-5 rounded-xl bg-indigo-600 px-7 py-7 text-white lg:flex-nowrap lg:px-12 lg:py-12">
        <div className="flex-grow text-center lg:text-left">
          <h2 className="text-2xl font-medium lg:text-3xl">
            Bereit, AIFred auszuprobieren?
          </h2>
          <p className="mt-2 font-medium text-white text-opacity-90 lg:text-xl">
            Erleichtere deinen Bewerbungsprozess und lass AIFred intelligente
            Antworten für dich erstellen.
          </p>
        </div>
        <div className="w-full flex-shrink-0 text-center lg:w-auto">
          <Link
            to={$path("/register")} // URL zur Registrierungsseite
            className="mx-auto inline-block rounded-md bg-white px-7 py-3 text-center text-lg font-medium text-indigo-600 lg:px-10 lg:py-5"
          >
            Jetzt kostenlos registrieren
          </Link>
        </div>
      </div>
    </Container>
  );
};
